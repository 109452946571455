canvas:focus {
  outline: none;
}

/* To adjust the draw widget */
.mapboxgl-ctrl-top-right {
  bottom: 140px;
  right: 14px;
}

.hidden {
  display: none;
}

.container-fluid {
  padding: 0;
}
