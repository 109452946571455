#firebaseui_container {
  width: 500px;
  text-transform: uppercase;
}

#firebaseui_container h1 {
  text-align: center;
  color: #20315a;
}

#firebaseui_container .firebaseui-container {
  box-shadow: none;
}

#firebaseui_container button {
  background-color: #20315a;
  color: white;
}

#firebaseAuthContainerAdmin .firebaseui-id-page-password-sign-up {
  display: block !important;
}
